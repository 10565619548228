export default {
  translations: {
    congratulations: "¡Felicidades!",
    youWon: "Acabas de ganar",
    balance: "Nuevo saldo de billetera",
    shopButton: "Comprar ahora",
    expiry: "Estas Recompensas vencerán el <0>{{expireAt}}</0>",
    error: "Error",
    errors: {
      claimError:
        "Hubo un error al canjear tu recompensa. Vuelve a intentarlo.",
      fetchError:
        "Hubo un error al intentar obtener la información. Vuelve a intentarlo.",
      restrictions: {
        title: "No puedes desbloquear esta recompensa",
        geoRestricted:
          "Parece que te encuentras en un país diferente a aquel en el que se creó tu Cartera, y la moneda de tu Cartera no es compatible en este país. Si te mudas a otro país y necesitas cambiar la moneda asociada a tu Cartera, contacta a nuestro servicio de soporte al cliente.",
        limitBalance:
          "Por motivos de seguridad, no puedes aplicar un crédito de más de {{ limitAmount }} a tu Cartera.",
        limitTopup:
          "Por motivos de seguridad, no puedes aplicar un crédito de más de {{ limitAmount }} a tu Cartera en cada periodo de 24 horas. ¡Regresa más tarde!"
      }
    },
    learnMore: "Aprender más"
  }
};
