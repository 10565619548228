export default {
  translations: {
    congratulations: "Parabéns!",
    youWon: "Você ganhou",
    balance: "Novo saldo de Carteira",
    shopButton: "Compre Agora",
    expiry: "Esta Recompensa expira em <0>{{expireAt}}</0>",
    error: "Erro",
    errors: {
      claimError:
        "Ocorreu um erro ao resgatar sua recompensa. Tente novamente.",
      fetchError:
        "Ocorreu um erro ao tentar obter as informações. Tente novamente.",
      restrictions: {
        title: "Você não pode desbloquear esta recompensa",
        geoRestricted:
          "Parece que você não está no país onde sua Carteira foi criada e a moeda da Carteira não é suportada neste país. Se estiver indo para outro país e precisar mudar a moeda associada à sua Carteira, contate nosso atendimento ao cliente.",
        limitBalance:
          "Por motivos de segurança o limite de crédito na sua Carteira é de {{ limitAmount }}.",
        limitTopup:
          "Por motivos de segurança o limite de crédito por 24 horas na sua Carteira é de {{ limitAmount }}. Retorne mais tarde!"
      }
    },
    learnMore: "Saber mais"
  }
};
