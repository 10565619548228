export default {
  translations: {
    congratulations: "Glückwunsch!",
    youWon: "Du gewinnst",
    balance: "Neues Wallet-Guthaben",
    shopButton: "Jetzt shoppen",
    expiry: "Diese Belohnungen verfallen am <0>{{expireAt}}</0>",
    error: "Fehler",
    errors: {
      claimError:
        "Beim Abholen deiner Belohnung ist ein Fehler aufgetreten. Bitte versuche es erneut.",
      fetchError:
        "Beim Abholen der Information ist ein Fehler aufgetreten. Bitte versuche es erneut.",
      restrictions: {
        title: "Du kannst diese Belohnung nicht freischalten.",
        geoRestricted:
          "Du scheinst dich in einem anderen Land zu befinden, als dem, in dem dein Wallet angelegt wurde oder die Währung deines Wallets wird in diesem Land nicht akzeptiert. Wenn du in ein neues Land umziehst und die Währung deines Wallets anpassen möchtest, dann wende dich bitte an den Kundenservice.",
        limitBalance:
          "Aus Sicherheitsgründen kannst du nicht mehr als {{ limitAmount }} in deinem Wallet verwahren.",
        limitTopup:
          "Aus Sicherheitsgründen kannst du innerhalb von 24 Stunden nicht mehr als {{limitAmount}} mit deinem Wallet bezahlen. Bitte versuche es später erneut."
      }
    },
    learnMore: "Mehr erfahren"
  }
};
