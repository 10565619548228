import Api from "@mooncake/mooncake-gateway-ecom";
import {
  fetchWalletRewards,
  claimReward
} from "@mooncake/mooncake-gateway-ecom/events/walletRewards";
import { createSlice } from "@reduxjs/toolkit";
import { REWARD_CARD_STATUSES } from "@mooncake/ui/lib/specifics/wallet/WalletRewardCard";
import i18n from "../../core/i18n";
import { fetchWalletAction, setBalance } from "../wallet/walletSlice";

export const initialState = {
  activeReward: null,
  claimableRewards: [],
  expiryDates: {},
  errors: {
    fetch: "",
    claim: ""
  }
};

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    setActiveReward(state, action) {
      state.activeReward = action.payload;
    },
    setClaimableRewards(state, action) {
      state.claimableRewards = action.payload;
    },
    setExpiryDate(state, action) {
      state.expiryDates[action.payload.promotionCode] = action.payload.date;
    },
    setError(state, action) {
      state.errors[action.payload.type] = action.payload.error;
    },
    resetState(state) {
      state.activeReward = initialState.activeReward;
      state.claimableRewards = initialState.claimableRewards;
      state.expiryDates = initialState.expiryDates;
      state.errors = initialState.errors;
    }
  }
});

export const fetchRewardsAction = (country, currency, locale) => dispatch => {
  return new Promise(resolve => {
    fetchWalletRewards(
      {
        country,
        currency,
        locale: locale.replace("-", "_")
      },
      ({ resource, error }) => {
        if (error || resource.message) {
          if (!error) {
            dispatch(setError({ type: "fetch", error: "" }));
          } else {
            dispatch(
              setError({
                type: "fetch",
                error: i18n.t("errors.fetchError")
              })
            );
          }
          resolve();
          return;
        }

        const formattedRewards = resource
          .filter(reward => reward.card.type === REWARD_CARD_STATUSES.achieved)
          .map(({ achieved_amount, ...reward }) => ({
            ...reward,
            achieved_amount: parseFloat(achieved_amount)
          }));
        dispatch(setClaimableRewards(formattedRewards));
        dispatch(setError({ type: "fetch", error: "" }));
        resolve(formattedRewards);
      }
    )();
  });
};

export const claimRewardAction = reward => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    claimReward({ reward }, ({ resource, error }) => {
      if (error) {
        dispatch(
          setError({
            type: "claim",
            error: i18n.t("errors.claimError")
          })
        );
        reject(error);
        return;
      }
      dispatch(
        setError({
          type: "claim",
          error: ""
        })
      );
      if (resource.expire_at) {
        dispatch(
          setExpiryDate({
            promotionCode: `${reward.promotion_code}-${reward.account_reward_id}`,
            date: new Date(resource.expire_at).toLocaleDateString(
              getState().app.locale,
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              }
            )
          })
        );
      }
      if (
        process.env.NODE_ENV !== "production" &&
        Api.environment === Api.ENVIRONMENTS.MOCK
      ) {
        // Simulates a random delay between 200ms and 2s
        setTimeout(() => {
          dispatch(
            setBalance({
              type: "new",
              balance: null
            })
          );
          dispatch(
            setBalance({
              type: "new",
              balance:
                getState().wallet.balance.current + reward.achieved_amount
            })
          );
        }, Math.random() * (200 - 200) + 200);
      } else {
        dispatch(fetchWalletAction("new"));
      }
      resolve();
    })();
  });
};

export const {
  setActiveReward,
  setClaimableRewards,
  setError,
  setExpiryDate
} = rewardsSlice.actions;

export default rewardsSlice.reducer;
