export default {
  translations: {
    congratulations: "Congratulations !",
    errors: {
      restrictions: {
        geoRestricted:
          "You seem to be in a different country than the one in which your Wallet was created and your Wallet currency is not supported in this country. If you are moving to a new country and need to change the currency associated to your Wallet, please contact our customer support.",
        limitBalance:
          "For security reasons you cannot credit more than {{ limitAmount }} in your Wallet.",
        limitTopup:
          "For security reasons you can't credit more than {{ limitAmount }} in your Wallet per rolling 24h. Please come back later !"
      }
    }
  }
};
