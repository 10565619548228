import PropTypes from "prop-types";
import { getPageUrl } from "@mooncake/utils";

export const CustomAppPropTypes = {
  linkLearnMoreGeoRestricted: PropTypes.string,
  linkLearnMoreLimitBalance: PropTypes.string,
  linkLearnMoreLimitTopup: PropTypes.string,
  shopNowUrl: PropTypes.string
};

export const CustomAppDefaultProps = {
  linkLearnMoreGeoRestricted: "https://support.ubisoft.com/faqs/57882/",
  linkLearnMoreLimitBalance: "https://support.ubisoft.com/faqs/57876/",
  linkLearnMoreLimitTopup: "https://support.ubisoft.com/faqs/57876/",
  shopNowUrl: getPageUrl("Search-Show", { cgid: "games" })
};

export const CustomAppEventsConfigs = {};
