export default {
  translations: {
    errors: {
      restrictions: {
        geoRestricted:
          "Vous semblez être dans un pays différent de celui dans lequel votre Porte-monnaie a été créé et la devise de votre Porte-monnaie n'est pas prise en charge dans ce pays. Si vous déménagez dans un nouveau pays et que vous devez changer la devise associée à votre Porte-monnaie, veuillez contacter notre service à la clientèle."
      }
    }
  }
};
