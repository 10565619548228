export default {
  translations: {
    congratulations: "Congratulations!",
    youWon: "You just won",
    balance: "New Wallet balance",
    shopButton: "Shop Now",
    expiry: "This Rewards will expire on <0>{{expireAt}}</0>",
    error: "Error",
    errors: {
      claimError:
        "An error occurred while claiming your reward. Please try again.",
      fetchError:
        "An error occurred while trying to get the information. Please try again.",
      restrictions: {
        title: "You cannot unlock this reward",
        geoRestricted:
          "You seem to be in a different country than the one in which your Wallet was created and your Wallet currency is not supported in this country. If you are moving to a new country and need to change the currency associated to your Wallet, please contact our customer support service.",
        currencyRestricted: "$t(errors.restrictions.geoRestricted)",
        limitBalance:
          "For security reasons, you cannot top up more than {{ limitAmount }} to your Wallet.",
        limitTopup:
          "For security reasons, you can't top up more than {{ limitAmount }} to your Wallet within 24h. Please come back later!"
      }
    },
    learnMore: "Learn more"
  }
};
