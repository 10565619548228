import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { MOONCAKE_DATA_UBICONNECT_FETCHED } from "@mooncake/mooncake-gateway-core/constants";
import {
  fetchWalletAction,
  fetchWalletLimitsAction,
  setLearnMoreLinks
} from "../../features/wallet/walletSlice";
import { fetchEnd, fetchStart } from "../../core/features/app/appSlice";
import {
  claimRewardAction,
  fetchRewardsAction,
  setActiveReward
} from "../../features/rewards/rewardsSlice";
import { getRestrictions } from "@mooncake/utils";
import ClaimModal from "../ClaimModal";
import ErrorModal from "../ErrorModal";
import { MOONCAKE_DATA_ID } from "../../constants";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      modalIsOpen: false,
      isClaimed: false
    };

    this.canShowRewards = !!(
      window.SitePreferences && window.SitePreferences.ENABLE_WALLET_REWARDS
    );

    this.setActiveReward = this.setActiveReward.bind(this);
    this.getRestrictions = this.getRestrictions.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.unbindModalEvent = this.unbindModalEvent.bind(this);
    this.setIsClaimed = this.setIsClaimed.bind(this);
    this.setError = this.setError.bind(this);
    this.startFetchingWallet = this.startFetchingWallet.bind(this);
  }

  startFetchingWallet(info) {
    document
      .getElementById(MOONCAKE_DATA_ID)
      .removeEventListener(
        MOONCAKE_DATA_UBICONNECT_FETCHED,
        this.startFetchingWallet
      );

    const {
      country,
      locale,
      storeCurrency,
      fetchEnd,
      fetchRewardsAction,
      fetchWalletAction,
      fetchWalletLimitsAction
    } = this.props;

    fetchRewardsAction(country, storeCurrency, locale)
      .then(() => {
        fetchEnd();
      })
      .catch(() => {
        fetchEnd();
      });
    fetchWalletAction("current", info.detail).then(() => {
      const userId = info.detail && info.detail.userId;
      const noWalletCookie = Cookies.get(`noWallet_${userId}`);
      if (!noWalletCookie) {
        fetchWalletLimitsAction();
      }
    });
  }

  componentDidMount() {
    if (this.canShowRewards) {
      const {
        fetchStart,
        linkLearnMoreGeoRestricted,
        linkLearnMoreLimitBalance,
        linkLearnMoreLimitTopup,
        setLearnMoreLinks
      } = this.props;
      setLearnMoreLinks({
        geoRestricted: linkLearnMoreGeoRestricted,
        limitBalance: linkLearnMoreLimitBalance,
        limitTopup: linkLearnMoreLimitTopup
      });
      fetchStart();
      document
        .getElementById(MOONCAKE_DATA_ID)
        .addEventListener(
          MOONCAKE_DATA_UBICONNECT_FETCHED,
          this.startFetchingWallet
        );
    }

    document.addEventListener("onClaimReward", this.setActiveReward);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.activeReward &&
      prevProps.activeReward !== this.props.activeReward
    ) {
      this.getRestrictions();
    }
  }

  setError(error) {
    this.setState({
      error
    });
  }

  onCloseModal() {
    const {
      country,
      currency,
      fetchRewardsAction,
      locale,
      setActiveReward
    } = this.props;

    this.unbindModalEvent();

    this.setState({
      modalIsOpen: false
    });

    setActiveReward(null);

    if (this.state.isClaimed) {
      fetchRewardsAction(country, currency, locale);
    }
  }

  getRestrictions() {
    const { activeReward, balance, currency, limits } = this.props;
    const restrictions = getRestrictions(
      balance,
      limits,
      activeReward.achieved_amount,
      currency
    );

    switch (restrictions) {
      case "noWallet":
        this.setError(null);
        document.dispatchEvent(new Event("openCurrencyConfirmationModal"));
        const confirmationModal = document.querySelector(
          "wallet-currency-confirmation-modal"
        );
        if (confirmationModal) {
          confirmationModal.addEventListener("onConfirm", this.openModal);
          confirmationModal.addEventListener("onCloseModal", this.onCloseModal);
        }
        break;
      case "currencyRestricted":
      case "geoRestricted":
      case "limitBalance":
      case "limitTopup":
        this.setError(restrictions);
        this.setState({
          modalIsOpen: true
        });
        break;
      case false:
      default:
        this.setError(null);
        this.openModal();
        break;
    }
  }

  componentWillUnmount() {
    this.unbindModalEvent();
    document.removeEventListener("onClaimReward", this.setActiveReward);
  }

  unbindModalEvent() {
    const confirmationModal = document.querySelector(
      "wallet-currency-confirmation-modal"
    );
    if (confirmationModal) {
      confirmationModal.removeEventListener("onConfirm", this.openModal);
      confirmationModal.removeEventListener("onCloseModal", this.onCloseModal);
    }
  }

  openModal() {
    const {
      activeReward,
      claimRewardAction,
      fetchStart,
      fetchEnd,
      fetchWalletAction
    } = this.props;

    fetchStart();
    this.setState({
      modalIsOpen: true
    });
    fetchWalletAction("current");
    claimRewardAction(activeReward)
      .then(() => {
        fetchEnd();
        this.setIsClaimed();
      })
      .catch(() => {
        fetchEnd();
      });
  }

  setIsClaimed() {
    this.setState({
      isClaimed: true
    });
  }

  setActiveReward(activeReward) {
    const { setActiveReward, rewards } = this.props;
    if (activeReward instanceof Event) {
      activeReward = activeReward.detail;
    }

    activeReward = rewards.find(
      reward => reward.account_reward_id === activeReward.account_reward_id
    );
    if (activeReward) {
      setActiveReward(activeReward);
    }
  }

  render() {
    const { error, shopNowUrl } = this.props;

    return (
      this.canShowRewards &&
      !error && (
        <>
          {!this.state.error && (
            <ClaimModal
              shopNowUrl={shopNowUrl}
              isOpen={this.state.modalIsOpen}
              onCloseModal={this.onCloseModal}
            />
          )}
          {this.state.error && (
            <ErrorModal
              isOpen={this.state.modalIsOpen}
              error={this.state.error}
              onCloseModal={this.onCloseModal}
            />
          )}
        </>
      )
    );
  }
}

const mapStateToProps = state => ({
  balance: state.wallet.balance.current,
  limits: state.wallet.limits,
  currency: state.wallet.currency,
  error: state.rewards.errors.fetch,
  activeReward: state.rewards.activeReward,
  rewards: state.rewards.claimableRewards
});

const mapDispatchToProps = {
  setActiveReward,
  setLearnMoreLinks,
  fetchStart,
  fetchEnd,
  fetchRewardsAction,
  fetchWalletAction,
  fetchWalletLimitsAction,
  claimRewardAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Content));
