import { Button } from "@mooncake/ui";
import styled, { css } from "styled-components";

export const StyledButton = styled(Button)(
  props =>
    props.theme.name === "Store" &&
    css`
      color: ${props.theme.colors.primary.variant};
    `
);
