export default {
  translations: {
    congratulations: "Félicitations!",
    youWon: "Vous avez gagné",
    balance: "Nouveau solde de Porte-monnaie",
    shopButton: "Acheter maintenant",
    expiry: "Cette Récompenses expirera le <0>{{expireAt}}</0>",
    error: "Erreur",
    errors: {
      claimError:
        "Une erreur s'est produite lors de la réclamation de votre récompense. Veuillez réessayer.",
      fetchError:
        "Une erreur s'est produite lors de l'obtention des renseignements. Veuillez réessayer.",
      restrictions: {
        title: "Vous ne pouvez pas débloquer cette récompense",
        geoRestricted:
          "Vous semblez être dans un pays différent de celui dans lequel votre Porte-monnaie a été créé et la devise de votre Porte-monnaie n'est pas prise en charge dans ce pays. Si vous déménagez dans un nouveau pays et que vous devez changer la devise associée à votre Porte-monnaie, veuillez contacter notre support client.",
        limitBalance:
          "Pour des raisons de sécurité, vous ne pouvez pas créditer plus de {{ limitAmount }} dans votre Porte-monnaie.",
        limitTopup:
          "Pour des raisons de sécurité, vous ne pouvez pas créditer plus de {{ limitAmount }} dans votre Porte-monnaie par période de 24 h. Veuillez revenir plus tard!"
      }
    },
    learnMore: "En savoir plus"
  }
};
