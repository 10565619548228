// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import { withTheme } from "styled-components";
// Mooncake
import {
  Container,
  FadingContainer,
  Icon,
  Loader,
  MultiStepModal,
  Text
} from "@mooncake/ui";
import {
  BUTTON_ANIMATIONS,
  BUTTON_COLORS
} from "@mooncake/ui/lib/components/Button";
import { Arrow, Warning } from "@mooncake/ui/lib/icons";
import { currencyFormatter } from "@mooncake/utils";
// Components
import UbisoftLogo from "../../assets/UbisoftLogo.svg";
import PriceSpinner from "../PriceSpinner";
import { StyledButton } from "./styles";

class ClaimModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canShowAmount: false,
      isReady: false
    };

    this.setCanShowAmount = this.setCanShowAmount.bind(this);
    this.shopNowAction = this.shopNowAction.bind(this);
  }

  componentDidMount() {
    if (this.props.balance && this.props.currentBalance) {
      this.setCanShowAmount();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.balance !== this.props.balance ||
      prevProps.currentBalance !== this.props.currentBalance
    ) {
      this.setCanShowAmount();
    }
    if (this.props.isOpen && !prevProps.isOpen) {
      try {
        window.tc_events_100(this, "clickevent", {
          ACTION: `rewards - claim rewards - ${this.props.activeReward.card.title}`
        });
      } finally {
        setTimeout(this.setCanShowAmount, 750);
      }
    }
  }

  setCanShowAmount() {
    // Wait for both the timeout and the wallet fetch to complete
    // Whichever finishes first sets isReady to true
    if (!this.state.isReady) {
      this.setState({
        isReady: true
      });
    } else {
      this.setState({
        canShowAmount: this.props.balance && this.props.currentBalance !== null
      });
    }
  }

  shopNowAction(e) {
    e.preventDefault();
    try {
      window.tc_events_100(this, "clickevent", {
        ACTION: "rewards - unlocked rewards - go to listing page - all games"
      });
    } finally {
      window.location.assign(e.target.href);
    }
  }

  render() {
    const {
      activeReward,
      balance,
      currentBalance,
      error,
      expiryDates,
      isOpen,
      loading,
      locale,
      onCloseModal,
      shopNowUrl,
      t,
      theme,
      themeKey
    } = this.props;

    const expireAt =
      activeReward &&
      expiryDates[
        `${activeReward.promotion_code}-${activeReward.account_reward_id}`
      ];

    return (
      <MultiStepModal
        className={"reward-modal"}
        width={{ default: "260px", mobile: "332px" }}
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        contentPosition={"center"}
        steps={[
          !loading && activeReward ? (
            <FadingContainer
              closeButtonColor={theme.colors.White}
              direction={"none"}
              className={`reward-modal reward-confirmation reward-confirmation--${
                error ? "error" : "success"
              }`}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              backgroundColor={
                error
                  ? theme.colors.error
                  : theme.WalletRewardCard.backgroundColor.achieved
              }
              backgroundImage={`url("${UbisoftLogo}")`}
              backgroundPosition={"center -55px"}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"133% auto"}
              padding={{ default: "30px 10px 20px", tablet: "45px 25px 50px" }}
              borderRadius={"4px"}
              color={theme.colors.White}
              textAlign={"center"}
              minHeight={{
                default: "375px",
                tablet: "500px"
              }}
              overflow={"hidden"}
            >
              {error && (
                <Container
                  maxWidth={"100%"}
                  marginTop={"auto"}
                  marginBottom={"auto"}
                >
                  <Container
                    className={"reward-confirmation__error-icon"}
                    marginBottom={"30px"}
                    fontSize={"60px"}
                  >
                    <Icon icon={Warning} color={"currentColor"} />
                  </Container>
                  <Text.Heading
                    className={"reward-confirmation__error-title"}
                    primary
                    bold
                  >
                    {t("error")}
                  </Text.Heading>
                  <Text.Subtitle
                    className={"reward-confirmation__error"}
                    primary
                    bold
                  >
                    {error}
                  </Text.Subtitle>
                </Container>
              )}

              {!error && (
                <Fragment>
                  <FadingContainer direction={"bottom"}>
                    <Text.Subtitle
                      className={"reward-confirmation__title"}
                      primary
                      bold
                    >
                      {t("congratulations")}
                    </Text.Subtitle>
                    <Text.Heading
                      className={"reward-confirmation__text"}
                      primary
                      bold
                      marginBottom={"26px"}
                    >
                      {t("youWon")}
                    </Text.Heading>
                  </FadingContainer>

                  <FadingContainer
                    className={"reward-confirmation__amount"}
                    animationDelay={".75s"}
                    direction={"bottom"}
                    padding={{ default: "10px 20px", tablet: "16px 24px" }}
                    backgroundColor={theme.colors.White}
                    borderRadius={"40px"}
                    boxShadow={"0 6px 15px rgba(0, 0, 0, 0.15)"}
                    marginBottom={"auto"}
                    width={"auto"}
                  >
                    <Text.Heading
                      primary
                      bold
                      color={
                        themeKey === "uplay"
                          ? theme.colors.primary.normal
                          : theme.colors.primary.variant
                      }
                      marginBottom={"0"}
                    >
                      +{" "}
                      {currencyFormatter(
                        activeReward.achieved_amount,
                        locale,
                        activeReward.currency,
                        {
                          shouldKeepEmptyDecimals: false
                        }
                      )}
                    </Text.Heading>
                  </FadingContainer>

                  {this.state.canShowAmount && (
                    <FadingContainer
                      animationDelay={".75s"}
                      className={"reward-confirmation__blance"}
                      direction={"bottom"}
                      display={"flex"}
                      flexDirection={"column"}
                      flexGrow={"1"}
                      alignItems={"center"}
                    >
                      <Container
                        className={"arrow"}
                        margin={"auto"}
                        fontSize={{ default: "16px", tablet: "21px" }}
                      >
                        <Icon
                          icon={Arrow}
                          color={theme.colors.White}
                          rotation={90}
                        />
                      </Container>
                      <Container
                        className={"balance"}
                        marginBottom={{ default: "20px", tablet: "30px" }}
                      >
                        <Text.Paragraph
                          className={"balance__title"}
                          secondary
                          bold
                          marginBottom={"5px"}
                        >
                          {t("balance")}
                        </Text.Paragraph>
                        <Text.Span
                          className={"balance__amount"}
                          fontSize={{ default: "20px", tablet: "28px" }}
                          bold
                        >
                          <PriceSpinner
                            fromAmount={currentBalance}
                            toAmount={balance}
                          />
                        </Text.Span>
                      </Container>
                    </FadingContainer>
                  )}
                  <FadingContainer direction={"bottom"} animationDelay={"2s"}>
                    <StyledButton
                      forwardedAs={"a"}
                      href={shopNowUrl}
                      className={"reward-confirmation__button"}
                      minWidt={"190px"}
                      color={BUTTON_COLORS.invertedPrimary}
                      animation={BUTTON_ANIMATIONS.invert}
                      onClick={this.shopNowAction}
                      outline={themeKey === "uplay"}
                    >
                      {t("shopButton")}
                    </StyledButton>

                    {expireAt && (
                      <Text.Paragraph
                        className={"reward-confirmation__expiry"}
                        fontSize={{ default: "13px", tablet: "15px" }}
                        marginTop={"20px"}
                      >
                        <Trans i18nKey={"expiry"}>
                          <Text.Span bold>{{ expireAt }}</Text.Span>
                        </Trans>
                      </Text.Paragraph>
                    )}
                  </FadingContainer>
                </Fragment>
              )}
            </FadingContainer>
          ) : (
            <Container
              minHeight={{
                default: "375px",
                tablet: "500px"
              }}
            >
              <Loader />
            </Container>
          )
        ]}
      ></MultiStepModal>
    );
  }
}

ClaimModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  shopNowUrl: PropTypes.string
};

const mapStateToProps = state => ({
  locale: state.app.locale,
  themeKey: state.app.themeKey,
  loading: state.app.loading,
  currentBalance: state.wallet.balance.current,
  balance: state.wallet.balance.new,
  currency: state.wallet.currency,
  activeReward: state.rewards.activeReward,
  error: state.rewards.errors.claim,
  expiryDates: state.rewards.expiryDates
});

export default connect(mapStateToProps)(
  withTranslation()(withTheme(ClaimModal))
);
