// Libs
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Mooncake
import { currencyFormatter } from "@mooncake/utils";

class PriceSpinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: props.fromAmount
    };
  }

  componentDidMount() {
    const { fromAmount, toAmount } = this.props;

    this.timeout = setTimeout(() => {
      const difference = toAmount - fromAmount;
      this.interval = setInterval(
        () => {
          const increment = difference % 1 === 0 ? 1 : difference / 10;
          const newValue = this.state.amount + increment;

          if (newValue <= toAmount) {
            this.setState({
              amount: newValue
            });
          } else {
            this.setState({
              amount: toAmount
            });
            clearInterval(this.interval);
          }
        },
        difference <= 5 ? 150 : 800 / difference
      );
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { locale, currency } = this.props;
    return currencyFormatter(this.state.amount, locale, currency);
  }
}

PriceSpinner.propTypes = {
  fromAmount: PropTypes.number.isRequired,
  toAmount: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  locale: state.app.locale,
  currency: state.wallet.currency
});

export default connect(mapStateToProps)(PriceSpinner);
