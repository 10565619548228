export default {
  translations: {
    congratulations: "Gefeliciteerd!",
    youWon: "Je wint",
    balance: "Nieuw Wallet tegoed",
    shopButton: "Shop nu",
    expiry: "Deze beloning vervalt op <0>{{expireAt}}</0>",
    error: "Fout",
    errors: {
      claimError:
        "Bij het claimen van je beloning is er een fout opgetreden. Probeer het later opnieuw.",
      fetchError:
        "Bij het ophalen van de gegevens is er een fout opgetreden. Probeer het later opnieuw.",
      restrictions: {
        title: "Je kan deze beloning niet ontgrendelen",
        geoRestricted:
          "Het lijkt erop dat je je in een ander land bevindt dan het land waarin je Wallet is gemaakt en je Wallet valuta wordt in dit land niet ondersteund. Als je naar een nieuw land verhuist en de valuta die aan je Wallet is gekoppeld, moet wijzigen, neem dan contact op met onze klantenservice.",
        limitBalance:
          "Om veiligheidsredenen kun je niet meer dan {{limitAmount}} aan je Wallet toevoegen.",
        limitTopup:
          "Om veiligheidsredenen kun je per 24 uur niet meer dan {{limitAmount}} aan je Wallet toevoegen. Probeer het later opnieuw."
      }
    },
    learnMore: "Meer info"
  }
};
