export default {
  translations: {
    congratulations: "Congratulazioni!",
    youWon: "Hai appena vinto",
    balance: "Nuovo saldo del Wallet",
    shopButton: "Acquista ora",
    expiry: "Queste Ricompense scadono il <0>{{expireAt}}</0>",
    error: "Errore",
    errors: {
      claimError:
        "Si è verificato un errore durante la richiesta della tua Ricompensa. Ti chiediamo di riprovare più tardi",
      fetchError:
        "Si è verificato un errore durante la richiesta di informazioni. Ti chiediamo di riprovare più tardi",
      restrictions: {
        title: "Non puoi sbloccare questa Ricompensa",
        geoRestricted:
          "Sembra che tu sia in un paese differente rispetto a quello dove il tuo Wallet è stato creato e la valuta del tuo Wallet non è supportata in questo paese. Se ti stai trasferendo in un altro paese e ti occorre cambiare la valuta associata al tuo Wallet, contatta il servizio clienti.",
        limitBalance:
          "Per motivi di sicurezza  puoi accreditare al massimo {{ limitAmount }} sul tuo Wallet.",
        limitTopup:
          "Per motivi di sicurezza  puoi accreditare al massimo {{ limitAmount }} sul tuo Wallet ogni 24 ore. Ritorna più tardi!"
      }
    },
    learnMore: "Scopri di più"
  }
};
