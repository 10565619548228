export default {
  translations: {
    congratulations: "恭喜！",
    youWon: "您刚刚获取",
    balance: "当前钱包余额",
    shopButton: "立即选购",
    expiry: "奖励将于 <0>{{expireAt}}</0> 过期",
    error: "出错",
    errors: {
      claimError: "获取您的奖励时出错。请稍后再试。",
      fetchError: "获取您的奖励时出错。请稍后再试。",
      restrictions: {
        title: "您无法解锁这个奖励",
        geoRestricted:
          "您似乎不在创建钱包时所在的国家或地区，所以您的钱包无法支持当前地区的支付。如果您移居到一个新的国家，并需要更改与您的钱包的币种，请联系我们的客服。",
        limitBalance: "出于安全原因，您不能向钱包充值超过{{ limitAmount }}。",
        limitTopup:
          "出于安全原因，您不能在24小时内向钱包充值超过{{ limitAmount }}。请稍后再试！"
      }
    },
    learnMore: "查看更多"
  }
};
