export default {
  translations: {
    congratulations: "Gratulacje!",
    youWon: "Udało ci się wygrać",
    balance: "Nowe saldo Portfela",
    shopButton: "Kupuj teraz",
    expiry: "Ta nagroda wygaśnie dnia <0>{{expireAt}}</0>",
    error: "Błąd",
    errors: {
      claimError:
        "Podczas odbierania twojej nagrody wystąpił błąd. Spróbuj ponownie.",
      fetchError:
        "Podczas próby pozyskania informacji wystąpił błąd. Spróbuj ponownie.",
      restrictions: {
        title: "Nie możesz odblokować tej nagrody",
        geoRestricted:
          "Wszystko wskazuje na to, ze znajdujesz się w innym kraju niż ten, w którym stworzono twój Portfel, i waluta twojego Portfela nie jest w nim wspierana. Jeśli zamierzasz zamieszkać w nowym kraju i musisz zmienić walutę swojego Portfela, skontaktuj się z naszą obsługą klienta.",
        limitBalance:
          "Ze względów bezpieczeństwa nie możesz dodać do swojego Portfela więcej niż {{ limitAmount }}.",
        limitTopup:
          "Ze względów bezpieczeństwa nie możesz dodać do swojego Portfela więcej niż {{ limitAmount }} na dobę."
      }
    },
    learnMore: "Dowiedz się więcej"
  }
};
