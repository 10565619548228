import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  fetchWallet,
  fetchWalletLimits
} from "@mooncake/mooncake-gateway-ecom/events/wallet";

const initialState = {
  balance: {
    current: null,
    new: null
  },
  currency: null,
  limits: null,
  learnMoreLinks: {
    geoRestricted: null,
    limitTopup: null,
    limitBalance: null
  }
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setBalance(state, action) {
      const { type, balance } = action.payload;
      state.balance[type] =
        typeof balance === "string" ? parseFloat(balance) : balance;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setLimits(state, action) {
      state.limits = action.payload;
    },
    setLearnMoreLinks(state, action) {
      state.learnMoreLinks = action.payload;
    }
  }
});

export const fetchWalletAction = (type, userInfo) => dispatch => {
  return new Promise(resolve => {
    dispatch(
      setBalance({
        type,
        balance: null
      })
    );
    // If userId has not been found in UbiConnect
    if (!userInfo || (userInfo && !userInfo.userId)) {
      resolve();
    }
    const noWalletCookie = Cookies.get(`noWallet_${userInfo.userId}`);
    // If the noWallet cookie exist we terminate the function
    if (noWalletCookie) {
      resolve();
    } else {
      fetchWallet(({ resource, error }) => {
        if (error) {
          const noWallet = error.response && error.response.status === 404;
          dispatch(setCurrency(null));
          if (noWallet) {
            const expirationTime = new Date(
              new Date().getTime() + 60 * 60 * 1000
            ); // One hour
            Cookies.set(`noWallet_${userInfo.userId}`, "1", {
              expires: expirationTime
            });
          }
          resolve();
          return;
        }

        dispatch(
          setBalance({
            type,
            balance: resource.total_balance
          })
        );
        dispatch(setCurrency(resource.currency));
        resolve();
      })({ refresh: true });
    }
  });
};

export const fetchWalletLimitsAction = () => dispatch => {
  return new Promise(resolve => {
    fetchWalletLimits(({ resource, error }) => {
      if (error) {
        dispatch(setLimits(null));
        resolve();
        return;
      }

      const limits = { ...resource };
      Object.keys(limits).forEach(key => {
        limits[key] = parseFloat(limits[key]);
      });
      dispatch(setLimits(limits));
      resolve(resource);
    })();
  });
};

export const {
  setBalance,
  setCurrency,
  setLearnMoreLinks,
  setLimits
} = walletSlice.actions;

export default walletSlice.reducer;
