import appReducer from "../core/features/app/appSlice";
import { combineReducers } from "redux";
import rewardsReducer from "../features/rewards/rewardsSlice";
import walletReducer from "../features/wallet/walletSlice";

export default combineReducers({
  app: appReducer,
  rewards: rewardsReducer,
  wallet: walletReducer
});
