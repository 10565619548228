export default {
  translations: {
    congratulations: "¡Enhorabuena!",
    balance: "Nuevo saldo del Wallet",
    errors: {
      claimError:
        "Se ha producido un error al canjear tu recompensa. Por favor, vuelve a intentarlo.",
      fetchError:
        "Se ha producido un error al intentar obtener la información. Por favor, vuelve a intentarlo.",
      restrictions: {
        geoRestricted:
          "Parece que te encuentras en un país diferente a aquel en el que se creó tu Wallet, y la moneda de tu Wallet no es compatible en este país. Si te mudas a otro país y necesitas cambiar la moneda asociada a tu Wallet, contacta a nuestro servicio de Atención al Cliente.",
        limitBalance:
          "Por motivos de seguridad, no puedes aplicar un crédito de más de {{ limitAmount }} a tu Wallet.",
        limitTopup:
          "Por motivos de seguridad, no puedes recargar más de {{ limitAmount }} en tu Wallet durante 24 horas. Vuelve a intentarlo más tarde."
      }
    },
    learnMore: "Saber más"
  }
};
