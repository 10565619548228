// Libs
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
// Mooncake
import { Button, Container, Modal, Text } from "@mooncake/ui";
import { BUTTON_COLORS } from "@mooncake/ui/lib/components/Button";
import { currencyFormatter } from "@mooncake/utils";

const ErrorModal = ({
  currency,
  error,
  onCloseModal,
  isOpen,
  learnMoreLinks,
  limits,
  locale,
  t,
  theme,
  themeKey
}) => {
  const linkLearnMore = learnMoreLinks[error];

  const errorMessageData = {};

  if (error === "limitBalance") {
    errorMessageData.limitAmount = currencyFormatter(
      limits.limit_total_balance,
      locale,
      currency
    );
  }

  if (error === "limitTopup") {
    errorMessageData.limitAmount = currencyFormatter(
      limits.limit_rolling_credit_amount,
      locale,
      currency
    );
  }

  const errorMessage = t(`errors.restrictions.${error}`, errorMessageData);

  return (
    <Modal
      contentMaxWidth={"1100px"}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    >
      <Container
        padding={{ default: "50px 35px", tablet: "60px" }}
        textAlign={"center"}
        fontSize={{ default: "13px", tablet: "16px" }}
        lineHeight={{ default: "1.31", tablet: "normal" }}
      >
        <Text.Heading
          fontSize={{
            default: "20px",
            tablet: themeKey === "uplay" ? "36px" : "20px"
          }}
          lineHeight={{ default: "1.55", tablet: "1.11" }}
          bold
          uppercase
          marginBottom={"1.5em"}
        >
          {t("errors.restrictions.title")}
        </Text.Heading>
        <Container maxWidth={"610px"} margin={"0 auto"}>
          <Text.Paragraph
            marginBottom={"50px"}
            textAlign={"center"}
            color={theme.colors.text.primary}
          >
            {errorMessage}
          </Text.Paragraph>
          <Button
            forwardedAs={"a"}
            href={linkLearnMore}
            outline={themeKey !== "uplay"}
            color={BUTTON_COLORS.primary}
            minWidth={themeKey === "uplay" ? "220px" : "184px"}
          >
            {t("learnMore")}
          </Button>
        </Container>
      </Container>
    </Modal>
  );
};

ErrorModal.propTypes = {
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func
};

const mapStateToProps = state => ({
  themeKey: state.app.themeKey,
  locale: state.app.locale,
  currency: state.wallet.currency,
  learnMoreLinks: state.wallet.learnMoreLinks,
  limits: state.wallet.limits
});

export default connect(mapStateToProps)(
  withTheme(withTranslation()(ErrorModal))
);
